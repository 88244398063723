.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.cardHeader {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  cursor: pointer;
  background: #fff;
  transition: background 0.1s ease-in-out;
  display: flex;
  align-items: center;
  width: 100%;
}

.cardHeader:hover {
  background: #eee;
  transition: background 0.1s ease-in-out;
}

.addChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
